.navigateBack {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
}

.iconWrapper {
  width: 24px;
  height: 24px;
  box-shadow: 0px 2px 9px 0px #1d72f41f;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-text-secondary);
}

.iconWrapper:hover {
  color: var(--color-primary);
}
