.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  padding-bottom: var(--spacing-3xl);
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: var(--spacing-l) var(--spacing-none);
    gap: var(--spacing-m);
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: var(--spacing-none);
    gap: var(--spacing-xs);
  }
}
