.subHeader {
  display: flex;
  align-items: center;
  height: 80px;
  min-height: 80px;
  background: var(--color-surface2);
}

.titleWrapper {
  width: 470px;
  padding: var(--spacing-none) var(--spacing-4xl);
}

.progressWrapper {
  padding: var(--spacing-none) var(--spacing-4xl);
  flex-grow: 1;
}

.progressText {
  display: flex;
  justify-content: end;
  width: 100%;
  margin: var(--spacing-none) var(--spacing-s);
}

.progressTitle {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-transform: uppercase;
  color: white;
  padding-top: 2px;
  flex-grow: 1;
}

.progressNumber {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  color: white;
}
