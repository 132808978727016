:root
  --sportsman-primary: #1D72F4
  --sportsman-hover: #4C8DFF
  --sportsman-focus-shadow-rgb: 29, 114, 244
  --sportsman-primary-rgb: 29, 114, 244

.theme-sportsman

  .btn-primary
    --bs-btn-color: white
    --bs-btn-bg: var(--sportsman-primary)
    --bs-btn-border-color: none

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportsman-hover)
    --bs-btn-hover-border-color: none

    --bs-btn-focus-shadow-rgb: var(--sportsman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportsman-hover)
    --bs-btn-active-border-color: none
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: white
    --bs-btn-disabled-bg: var(--sportsman-primary)
    --bs-btn-disabled-border-color: none
    --td-theme-active-bg: var(--sportsman-primary)

  .btn-primary-second
    --bs-btn-color: white
    --bs-btn-bg: var(--sportsman-primary)
    --bs-btn-border-color: var(--sportsman-primary)

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportsman-hover)
    --bs-btn-hover-border-color: var(--sportsman-hover)

    --bs-btn-focus-shadow-rgb: var(--sportsman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportsman-hover)
    --bs-btn-active-border-color: var(--sportsman-hover)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: white
    --bs-btn-disabled-bg: var(--sportsman-primary)
    --bs-btn-disabled-border-color: var(--sportsman-primary)
    --td-theme-active-bg: var(--sportsman-primary)

  .bg-primary-second-hover:hover
    --bs-bg-opacity: .9
    background-color: rgba(var(--sportsman-primary-rgb), var(--bs-bg-opacity)) !important

  .bg-primary-second
    --bs-primary-second-rgb: var(--sportsman-focus-shadow-rgb)
    &.rounded-circle
      background-color: var(--sportsman-primary) !important

  .text-primary-second
    color: var(--sportsman-primary) !important

  .btn-outline-primary
    --bs-btn-color: var(--sportsman-primary)
    --bs-btn-border-color: var(--sportsman-primary)

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportsman-primary)
    --bs-btn-hover-border-color: var(--sportsman-primary)

    --bs-btn-focus-shadow-rgb: var(--sportsman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportsman-primary)
    --bs-btn-active-border-color: var(--sportsman-primary)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: var(--sportsman-primary)
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: var(--sportsman-primary)
    --bs-gradient: none

  .btn-outline-primary-second
    --bs-btn-color: var(--sportsman-primary)
    --bs-btn-border-color: var(--sportsman-primary)

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportsman-primary)
    --bs-btn-hover-border-color: var(--sportsman-primary)

    --bs-btn-focus-shadow-rgb: var(--sportsman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportsman-primary)
    --bs-btn-active-border-color: var(--sportsman-primary)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: var(--sportsman-primary)
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: var(--sportsman-primary)
    --bs-gradient: none

  .form-control:focus, .react-tel-input .form-control:focus
    border-color: var(--sportsman-primary)
    & + .input-group-text
      border: var(--bs-border-width) solid var(--sportsman-primary)

    .form-select:focus
      border-color: var(--sportsman-primary)
      box-shadow: none !important

  .tempus-dominus-widget.light
    --td-theme-active-bg: var(--sportsman-primary)
    --td-theme-renge-bg: rgba(217, 44, 158, 0.2)

  .form-check-input:checked
    background-color: white
    border-color: var(--sportsman-primary)

  .form-check-input:checked[type=checkbox]
    --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>")
    background-color: var(--sportsman-primary)

  .form-check-input:checked[type=radio]
    --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%231D72F4'/></svg>")

  .form-check-input:focus
    box-shadow: 0 0 0 0.2rem rgba(29, 114, 244, 0.2)

  .bg-primary-checked:checked
    --bs-bg-opacity: 1
    background-color: rgba(var(--sportsman-primary-rgb), var(--bs-bg-opacity)) !important

  .border-primary-checked:checked
    --bs-border-opacity: 1
    --bs-border-color: rgba(var(--sportsman-primary-rgb), var(--bs-border-opacity)) !important
    --bs-border-opacity: 1
    border-color: rgba(var(--sportsman-primary-rgb), var(--bs-border-opacity)) !important

  .focus-ring-primary
    --bs-focus-ring-color: rgba(var(--sportsman-primary-rgb), var(--bs-focus-ring-opacity))

  .border-primary-focus:focus
    --bs-border-color: rgba(var(--sportsman-primary-rgb), var(--bs-border-opacity)) !important
    border-color: rgba(var(--sportsman-primary-rgb), var(--bs-border-opacity)) !important

  .input-group-text
    border: var(--bs-border-width) solid #e9ecef

  .input-group > .reversed-group-text
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important
    border-top-left-radius: 0.5rem !important
    border-bottom-left-radius: 0.5rem !important

  .reversed-input-group.input-group .form-control
    border-top-right-radius: 0.5rem !important
    border-bottom-right-radius: 0.5rem !important
    border-top-left-radius: 0rem !important
    border-bottom-left-radius: 0rem !important

  .dropdown-menu
    --bs-dropdown-bg: white
    --bs-dropdown-border-color: var(--sportsman-primary)
    --bs-dropdown-box-shadow: 0 0 1rem rgba(29, 114, 244, 0.15)

  .dropdown-item.active, .dropdown-item:active
    --bs-dropdown-link-active-bg: var(--sportsman-primary)

  .wizard-step .border-primary-second
    border-color: rgba(var(--sportsman-primary-rgb), var(--bs-border-opacity)) !important
