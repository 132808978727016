.sidebar {
  display: flex;
  flex-direction: column;
  background: white;
  width: 304px;
  min-width: 304px;
  box-shadow: 2px 0px 6px 0px #2735500f;
  z-index: 1;
  overflow: hidden;
}

.sidebar:hover {
  overflow: auto;
}

.header {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--spacing-m) var(--spacing-4xl);
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  text-decoration: none;
}

.headerTitle:hover {
  opacity: 0.9;
}

.guideName {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  color: var(--color-text-primary);
  padding-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: var(--spacing-3xl);
  height: var(--spacing-3xl);
}

.closeIconWrapper {
  display: none;
  align-items: center;
  cursor: pointer;
  color: var(--color-text-secondary);
}

.closeIconWrapper:hover {
  color: var(--color-primary);
}

.sidebarBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-l) var(--spacing-m) var(--spacing-4xl) var(--spacing-m);
}

.logoutButton {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 11000;
    display: flex;
    overflow: auto;
  }

  .open {
    transition: all 0.3s ease;
    right: 0px;
    opacity: 1;
  }

  .close {
    transition: all 0.3s ease;
    right: 100%;
    height: 100vh;
    opacity: 0;
  }

  .header {
    box-shadow: 0px 0px 11px 0px #00000017;
    margin-bottom: var(--spacing-xxs);
  }

  .avatar {
    width: 44px;
    height: 44px;
  }

  .closeIconWrapper {
    display: flex;
  }

  .sidebarBody {
    overflow: auto;
  }

  .logoutButton {
    display: block;
    width: 103px;
    margin: var(--spacing-none) var(--spacing-xl);
  }
}

@media only screen and (max-width: 767px) {
  .logoutButton {
    width: auto;
    margin: var(--spacing-none) var(--spacing-m);
  }
}
