.codeInput {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.codeInput :global(.ant-input) {
  width: 100%;
  max-width: 88px;
  text-align: center;
}
