.sider {
  background-color: #ffffff;
  border-right: 1.5px solid #E9ECEF;
}

.searchInput {
  border-radius: var(--border-radius-xl);
}

.searchInput:global(.ant-input-affix-wrapper .ant-input-prefix) {
  margin-inline-end: 8px;
}

.searchBox {
  padding: 20px 16px;
  border-top: 1.5px solid #E9ECEF;
  border-bottom: 1.5px solid #E9ECEF;
}

.list {
  display: flex; 
  flex-direction: 
  column; flex: 1; 
  overflow-y: auto;
}

.list:global(.ant-list-split .ant-list-item:last-child) {
  border-block-end: 1.5px solid#E9ECEF;
}
