.header {
  background: #ffffff;
  padding: 10px 16px;
  border: 1.5px solid#E9ECEF;
  border-left: none;
  height: 85px;
}

.smallScreen .row {
  flex-direction: row-reverse;
}
