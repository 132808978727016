*
  scrollbar-width: thin
  font-synthesis: none
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  &:before,
  &:after
    font-synthesis: none
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

html,
body
  overscroll-behavior-y: none

body
  min-height: 100vh
  min-height: 100dvh
  overflow-x: hidden

  > iframe
    display: none

#root
  position: relative
  // z-index: 1

.logo
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: white
  text-align: center

.logo--gray
  color: var(--bs-gray-600)

.icon,
.icon path
  fill: currentColor!important

  [class*="fill-"] &
    fill: inherit!important

.scrollbar-none::-webkit-scrollbar
  display: none

.full-window-block
  --free-space: calc((100vw - 100%) / 2)
  --mx: calc(var(--free-space) * -1)
  --px: var(--free-space)
  display: flex
  margin: 0 var(--mx)
  padding: 0 var(--px)
  &.no-padding
    padding: 0

.full-window-scroll
  --free-space: calc((100vw - 100%) / 2)
  --mx: calc(var(--free-space) * -1)
  --px: var(--free-space)
  display: flex
  margin: 0 var(--mx)
  padding: 0 var(--px)
  overflow-x: auto

  &__content
    margin: 0 var(--mx)
    padding: 0 var(--px)

.scroll-shadow
  --scroll-shadow: #{rgba(var(--bs-dark-rgb), .5)}
  &-top
    box-shadow: 0px -7px 10px -10px var(--scroll-shadow)
  &-bottom
    box-shadow: 0px 7px 10px -10px var(--scroll-shadow)
  &-start
    box-shadow: -7px 0px 10px -10px var(--scroll-shadow)
  &-start
    box-shadow: -7px 0px 10px -10px var(--scroll-shadow)
  &-end
    box-shadow: 7px 0px 10px -10px var(--scroll-shadow)

.link-target
  position: relative
  transform: scale(1)

  a,
  button,
  label
    &:not(.link-target-skip):before
      content: ''
      display: block
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0

  .link-target-skip
    z-index: 1

.link
  padding: 0
  border: none
  outline: none
  background: none
  text-decoration: none
  &:hover
    color: currentColor
    text-decoration: underline

.show-on-hover
  &-target
    opacity: 0
    transition: .1s ease-in-out
  &:hover &-target
    opacity: 1

.hover-target
  &:not(:hover) &__show-on-hover
    opacity: 0

.react-player
  video
    display: block
    object-fit: cover

.backdrop-blur
  &-3
    backdrop-filter: blur(3px)
  &-6
    backdrop-filter: blur(6px)
  &-9
    backdrop-filter: blur(9px)
  &-12
    backdrop-filter: blur(12px)

.filter-wb
  filter: saturate(0) contrast(1.1)

.sms-text *
  margin-left: 0 !important
  margin-right: auto !important
  font-weight: normal !important
  text-align: left !important
  font-size: inherit !important

// Helpers

.public-DraftEditorPlaceholder-root,
.DraftEditor-editorContainer
  z-index: 0

.lightbox-media
  > *
    pointer-events: auto

:root
  --swiper-pagination-color: white
  --swiper-pagination-bullet-size: 5px
  --swiper-pagination-bullet-inactive-color: white
  --swiper-pagination-bullet-inactive-opacity: 0.5
  --swiper-pagination-bullet-horizontal-gap: 3px
.swiper-button-prev,
.swiper-button-next
  pointer-events: auto
  &:after
    content: none!important

.window-sensors
  position: fixed
  top: 0
  left: 0

.cover
  position: absolute
  top: 0
  left: 0
  width: 100%!important
  height: 100%!important

.lazy-load-image-background
  background-size: cover!important
  background-position: center!important
  transform: translateZ(0)

.two-point-range-wrapper
  position: relative

.two-point-range[type="range"]
  --from: 0%
  --to: 100%
  --track-default-fill: var(--bs-secondary-bg)
  --fill: var(--bs-primary-second)
  --track-bg: linear-gradient(to right, var(--track-default-fill) 0%, var(--track-default-fill) var(--from), var(--fill) var(--from), var(--fill) var(--to), var(--track-default-fill) var(--to), var(--track-default-fill) 100%)

  pointer-events: none

  &::-webkit-slider-runnable-track
    background: var(--track-bg)
  &::-moz-range-track
    background: var(--track-bg)
  &::-ms-track
    background: var(--track-bg)

  &:not(:first-child)
    position: absolute
    top: 0
    left: 0

  &:not(:first-child)::-webkit-slider-runnable-track
    box-shadow: none
    background: transparent
  &:not(:first-child)::-moz-range-thumb
    box-shadow: none
    background: transparent
  &:not(:first-child)::-ms-thumb
    box-shadow: none
    background: transparent

  &::-webkit-slider-thumb
    pointer-events: auto
  &::-moz-range-thumb
    pointer-events: auto
  &::-ms-thumb
    pointer-events: auto

.map-bottom-dropdown
  transform: none!important
  inset: auto 0px 0px 0px!important

.contact-templates.accordion
  --bs-accordion-btn-icon-width: 16px
  .accordion-button
    padding: 0
    border-radius: 0
    background: transparent
    &::after
      order: -1
      margin-left: 0

.Toastify__toast
  max-height: 100%

.theme-toggle
  --bs-form-switch-bg: url('../../src/assets/images/logo_circle.png') !important
.theme-toggle.form-check-input
  height: 27px
  width: 53px