/* Width Classes */

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-fit {
  width: fit-content;
}

.min-w-fit {
  min-width: fit-content;
}

.max-w-fit {
  max-width: fit-content;
}

.w-max {
  width: max-content;
}

.min-w-max {
  min-width: max-content;
}

.max-w-max {
  max-width: max-content;
}

.w-min {
  width: min-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-min {
  max-width: min-content;
}

/* Height Classes */

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/4 {
  height: 25%;
}

.h-fit {
  height: fit-content;
}

.min-h-fit {
  min-height: fit-content;
}

.max-h-fit {
  max-height: fit-content;
}

.h-max {
  height: max-content;
}

.min-h-max {
  min-height: max-content;
}

.max-h-max {
  max-height: max-content;
}

.h-min {
  height: min-content;
}

.min-h-min {
  min-height: min-content;
}

.max-h-min {
  max-height: min-content;
}
