.layout {
  height: 100vh;
  background-color: white;
}

.leftSide {
  background-size: cover;
  background-position: center;
}

.sportsman.signup {
  background-image: url(../../assets/imagesV2/auth/client/bg_signup_man.jpg);
}

.sportswoman.signup {
  background-image: url(../../assets/imagesV2/auth/client/bg_signup_woman.jpg);
}

.sportsman.signin {
  background-image: url(../../assets/imagesV2/auth/client/sign_in.jpg);
}

.sportswoman.signin {
  background-image: url(../../assets/imagesV2/auth/client/bg_signup_woman.jpg);
}

.guide_user.signup {
  background-image: url(../../assets/imagesV2/auth/pro/bg_signup.jpg);
}

.guide_user.signin {
  background-image: url(../../assets/imagesV2/auth/pro/bg_signup.jpg);
}

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 20px;
}

.formContent {
  width: 100%;
  max-width: 488px;
  display: flex;
  flex-direction: column;
}

.logo {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 40px;
}
