.menu-btn
  --menu-btn-width: 21px
  --menu-btn-height: 16px
  --menu-btn-line-width: 2px
  --menu-btn-line-offset: 4px
  --menu-btn-speed: .2s

  &__burger
    display: block
    width: var(--menu-btn-width)
    height: var(--menu-btn-height)
    position: relative
    font-size: 0
    color: currentColor
    appearance: none
    border: none
    outline: none
    background: none
    &:hover
      color: currentColor

    span
      width: 100%
      top: 50%
      left: 0

    span,
    span:before,
    span:after
      content: ''
      display: block
      height: var(--menu-btn-line-width)
      position: absolute
      border-radius: 4px
      background-color: currentColor
      transition-duration: var(--menu-btn-speed)
      transition-timing-function: ease-in-out
      transition-property: background, transform, width

    span:before,
    span:after
      top: 0
      left: 0
      width: 100%
    &:not(.full-w) span:before
      width: 80%
    &:not(.full-w) span:after
      width: 55%

    span
      transform: translateY(calc((var(--menu-btn-line-width) + var(--menu-btn-line-offset)) * -1))
    span:before
      transform: translateY(calc((var(--menu-btn-line-width) + var(--menu-btn-line-offset)) * 1))
    span:after
      transform: translateY(calc((var(--menu-btn-line-width) + var(--menu-btn-line-offset)) * 2))

  &[aria-expanded=true] &__burger span
    transform: rotate(45deg)
  &[aria-expanded=true] &__burger span:before
    background-color: transparent
    width: 0
  &[aria-expanded=true] &__burger span:after
    transform: rotate(-90deg)
    width: 100%
