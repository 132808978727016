.card {
  width: 272px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-m);
  box-shadow: 0px 0px 11px 0px #00000017;
  cursor: pointer;
  position: relative;
}

.card:hover {
  background: var(--color-blue-surface);
}

.card:active {
  box-shadow: 0px 0px 6px 0px #00000017;
}

.disabledCard {
  cursor: not-allowed;
}

.disabledCard:hover {
  background: white;
}

.cardIcon {
  padding: var(--spacing-l);
}

.cardTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: var(--color-primary);
}

.comingSoon {
  color: var(--color-text-secondary);
  text-transform: capitalize;
  position: absolute;
  bottom: 20px;
}

@media only screen and (max-width: 1024px) {
  .card {
    width: 219px;
  }
}

@media only screen and (max-width: 767px) {
  .card {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--spacing-s);
    padding: var(--spacing-m);
    border-radius: var(--border-radius-s);
  }

  .cardIcon {
    padding: var(--spacing-none);
  }

  .comingSoon {
    right: 20px;
  }
}
