@import "bootstrap/bootstrap_config";
@import "bootstrap/bootstrap_custom";
@import "bootstrap/bootstrap_extends";
@import "bootstrap/bootstrap_tempusdominus";
@import "sportsman_theme";
@import "sportswoman_theme";

#root {
  opacity: 1;
}
