.menuGroupItem {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: var(--spacing-none) var(--spacing-s);
  border-radius: var(--border-radius-s);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--color-text-secondary);
  cursor: pointer;
}

.menuGroupItem:hover {
  opacity: 0.85;
}

.menuGroupItemCompleted {
  color: var(--color-text-primary);
  background: var(--color-surface2);
}

.menuGroupItemActive {
  color: var(--color-primary);
  border: 1.5px solid var(--color-primary);
  background: var(--color-surface2);
  font-weight: 500;
}
