.menuGroupItems {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.menuGroupHeader {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-none);
}

.menuGroupNumber {
  display: flex;
  width: 24px;
  height: 24px;
  background: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
}

.menuGroupTitle {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-primary);
  text-transform: uppercase;
  line-height: 16.8px;
}
