.messageWrapper:global(.ant-list-item) {
  border-block-end: none;
}

.messageBody {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.ownMessage .messageBody {
  justify-content: flex-end;
}

.ownMessage .messageBody :global(.ant-typography){
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--border-radius-l) 16px var(--border-radius-xs) var(--border-radius-l);
  background-color: var(--color-primary);
  align-self: flex-end;
  max-width: 50%;
}

.messageBody :global(.ant-typography) {
  border-radius: 16px var(--border-radius-l) var(--border-radius-l) var(--border-radius-xs);
  background-color: var(--color-surface1);
  padding: var(--spacing-xs) var(--spacing-m);
  margin-bottom: var(--spacing-xxs);
  align-self: flex-start;
  max-width: 50%;
}

.messageWrapper.isMobile .messageBody :global(.ant-typography) {
  max-width: 90%;
}

.messages {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avatarWrapper {
  margin-right: var(--spacing-m);
  width: 52px;
}

.isMobile .avatarWrapper {
  width: 32px;
  margin-right: var(--spacing-xs);
}

.unread {
  background-color: var(--color-surface2);
  display: flex;
  justify-content: center;
  padding: var(--spacing-xxs) 0;
}


.imgMessage {
  max-width: 40%;
}

@media only screen and (max-width: 767px) {
  .imgMessage {
    max-width: 70%;
  }
}