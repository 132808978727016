

.conversation:global(.ant-list-item) {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1.5px solid#E9ECEF;
}


.conversation .unviewed {
  background-color: var(--color-primary);
  padding: var(--spacing-xxs) var(--spacing-xs);
  width: 28px;
  border-radius: var(--border-radius-l);
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  align-self: flex-start;
}
