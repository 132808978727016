.layout {
  display: flex;
  flex-direction: column;
  background: white;
  margin: var(--spacing-none) var(--spacing-6xl);
}

.hasMaxWidth {
  max-width: 800px;
}

.header {
  flex-direction: column;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-3xl);
}

@media only screen and (max-width: 1024px) {
  .layout {
    margin: var(--spacing-none) var(--spacing-4xl);
  }

  .header {
    margin-bottom: var(--spacing-none);
  }
}

@media only screen and (max-width: 767px) {
  .layout {
    margin: var(--spacing-m);
  }

  .header {
    margin-bottom: var(--spacing-xl);
  }
}
