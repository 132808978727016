.contentLayout {
  display: flex;
  flex-direction: row;
}

.content {
  padding: var(--spacing-4xl);
  background: white;
  display: flex;
  flex-direction: column;
}
