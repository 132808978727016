/* Padding Classes */
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.p-xxs {
  padding: 4px;
}

.pt-xxs {
  padding-top: 4px;
}

.pb-xxs {
  padding-bottom: 4px;
}

.pl-xxs {
  padding-left: 4px;
}

.pr-xxs {
  padding-right: 4px;
}

.p-xs {
  padding: 8px;
}

.pt-xs {
  padding-top: 8px;
}

.pb-xs {
  padding-bottom: 8px;
}

.pl-xs {
  padding-left: 8px;
}

.pr-xs {
  padding-right: 8px;
}

.p-s {
  padding: 12px;
}

.pt-s {
  padding-top: 12px;
}

.pb-s {
  padding-bottom: 12px;
}

.pl-s {
  padding-left: 12px;
}

.pr-s {
  padding-right: 12px;
}

.p-m {
  padding: 16px;
}

.pt-m {
  padding-top: 16px;
}

.pb-m {
  padding-bottom: 16px;
}

.pl-m {
  padding-left: 16px;
}

.pr-m {
  padding-right: 16px;
}

.p-l {
  padding: 20px;
}

.pt-l {
  padding-top: 20px;
}

.pb-l {
  padding-bottom: 20px;
}

.pl-l {
  padding-left: 20px;
}

.pr-l {
  padding-right: 20px;
}

.p-xl {
  padding: 24px;
}

.pt-xl {
  padding-top: 24px;
}

.pb-xl {
  padding-bottom: 24px;
}

.pl-xl {
  padding-left: 24px;
}

.pr-xl {
  padding-right: 24px;
}

.p-xxl {
  padding: 28px;
}

.pt-xxl {
  padding-top: 28px;
}

.pb-xxl {
  padding-bottom: 28px;
}

.pl-xxl {
  padding-left: 28px;
}

.pr-xxl {
  padding-right: 28px;
}

.p-3xl {
  padding: 32px;
}

.pt-3xl {
  padding-top: 32px;
}

.pb-3xl {
  padding-bottom: 32px;
}

.pl-3xl {
  padding-left: 32px;
}

.pr-3xl {
  padding-right: 32px;
}

.p-4xl {
  padding: 40px;
}

.pt-4xl {
  padding-top: 40px;
}

.pb-4xl {
  padding-bottom: 40px;
}

.pl-4xl {
  padding-left: 40px;
}

.pr-4xl {
  padding-right: 40px;
}

.p-5xl {
  padding: 60px;
}

.pt-5xl {
  padding-top: 60px;
}

.pb-5xl {
  padding-bottom: 60px;
}

.pl-5xl {
  padding-left: 60px;
}

.pr-5xl {
  padding-right: 60px;
}

/* Padding Vertical Classes */

.py-xxs {
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
}

.py-xs {
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}

.py-s {
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
}

.py-m {
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
}

.py-l {
  padding-top: 1.25rem /* 20px */;
  padding-bottom: 1.25rem /* 20px */;
}

.py-xl {
  padding-top: 1.5rem /* 24px */;
  padding-bottom: 1.5rem /* 24px */;
}

.py-xxl {
  padding-top: 1.75rem /* 28px */;
  padding-bottom: 1.75rem /* 28px */;
}

.py-3xl {
  padding-top: 2rem /* 32px */;
  padding-bottom: 2rem /* 32px */;
}

.py-4xl {
  padding-top: 2.5rem /* 40px */;
  padding-bottom: 2.5rem /* 40px */;
}

.py-5xl {
  padding-top: 3.75rem /* 60px */;
  padding-bottom: 3.75rem /* 60px */;
}

/* Padding Horizontal Classes */

.px-xxs {
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
}

.px-xs {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
}

.px-s {
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
}

.px-m {
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}

.px-l {
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
}

.px-xl {
  padding-left: 1.5rem /* 24px */;
  padding-right: 1.5rem /* 24px */;
}

.px-xxl {
  padding-left: 1.75rem /* 28px */;
  padding-right: 1.75rem /* 28px */;
}

.px-3xl {
  padding-left: 2rem /* 32px */;
  padding-right: 2rem /* 32px */;
}

.px-4xl {
  padding-left: 2.5rem /* 40px */;
  padding-right: 2.5rem /* 40px */;
}

.px-5xl {
  padding-left: 3.75rem /* 60px */;
  padding-right: 3.75rem /* 60px */;
}

/* Margin Classes */
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.m-xxs {
  margin: 4px;
}

.mt-xxs {
  margin-top: 4px;
}

.mb-xxs {
  margin-bottom: 4px;
}

.ml-xxs {
  margin-left: 4px;
}

.mr-xxs {
  margin-right: 4px;
}

.m-xs {
  margin: 8px;
}

.mt-xs {
  margin-top: 8px;
}

.mb-xs {
  margin-bottom: 8px;
}

.ml-xs {
  margin-left: 8px;
}

.mr-xs {
  margin-right: 8px;
}

.m-s {
  margin: 12px;
}

.mt-s {
  margin-top: 12px;
}

.mb-s {
  margin-bottom: 12px;
}

.ml-s {
  margin-left: 12px;
}

.mr-s {
  margin-right: 12px;
}

.m-m {
  margin: 16px;
}

.mt-m {
  margin-top: 16px;
}

.mb-m {
  margin-bottom: 16px;
}

.ml-m {
  margin-left: 16px;
}

.mr-m {
  margin-right: 16px;
}

.m-l {
  margin: 20px;
}

.mt-l {
  margin-top: 20px;
}

.mb-l {
  margin-bottom: 20px;
}

.ml-l {
  margin-left: 20px;
}

.mr-l {
  margin-right: 20px;
}

.m-xl {
  margin: 24px;
}

.mt-xl {
  margin-top: 24px;
}

.mb-xl {
  margin-bottom: 24px;
}

.ml-xl {
  margin-left: 24px;
}

.mr-xl {
  margin-right: 24px;
}

.m-xxl {
  margin: 28px;
}

.mt-xxl {
  margin-top: 28px;
}

.mb-xxl {
  margin-bottom: 28px;
}

.ml-xxl {
  margin-left: 28px;
}

.mr-xxl {
  margin-right: 28px;
}

.m-3xl {
  margin: 32px;
}

.mt-3xl {
  margin-top: 32px;
}

.mb-3xl {
  margin-bottom: 32px;
}

.ml-3xl {
  margin-left: 32px;
}

.mr-3xl {
  margin-right: 32px;
}

.m-4xl {
  margin: 40px;
}

.mt-4xl {
  margin-top: 40px;
}

.mb-4xl {
  margin-bottom: 40px;
}

.ml-4xl {
  margin-left: 40px;
}

.mr-4xl {
  margin-right: 40px;
}

.m-5xl {
  margin: 60px;
}

.mt-5xl {
  margin-top: 60px;
}

.mb-5xl {
  margin-bottom: 60px;
}

.ml-5xl {
  margin-left: 60px;
}

.mr-5xl {
  margin-right: 60px;
}

/* Margin Vertical Classes */

.my-xxs {
  margin-top: 0.25rem /* 4px */;
  margin-bottom: 0.25rem /* 4px */;
}

.my-xs {
  margin-top: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
}

.my-s {
  margin-top: 0.75rem /* 12px */;
  margin-bottom: 0.75rem /* 12px */;
}

.my-m {
  margin-top: 1rem /* 16px */;
  margin-bottom: 1rem /* 16px */;
}

.my-l {
  margin-top: 1.25rem /* 20px */;
  margin-bottom: 1.25rem /* 20px */;
}

.my-xl {
  margin-top: 1.5rem /* 24px */;
  margin-bottom: 1.5rem /* 24px */;
}

.my-xxl {
  margin-top: 1.75rem /* 28px */;
  margin-bottom: 1.75rem /* 28px */;
}

.my-3xl {
  margin-top: 2rem /* 32px */;
  margin-bottom: 2rem /* 32px */;
}

.my-4xl {
  margin-top: 2.5rem /* 40px */;
  margin-bottom: 2.5rem /* 40px */;
}

.my-5xl {
  margin-top: 3.75rem /* 60px */;
  margin-bottom: 3.75rem /* 60px */;
}

/* Margin Horizontal Classes */

.mx-xxs {
  margin-left: 0.25rem /* 4px */;
  margin-right: 0.25rem /* 4px */;
}

.mx-xs {
  margin-left: 0.5rem /* 8px */;
  margin-right: 0.5rem /* 8px */;
}

.mx-s {
  margin-left: 0.75rem /* 12px */;
  margin-right: 0.75rem /* 12px */;
}

.mx-m {
  margin-left: 1rem /* 16px */;
  margin-right: 1rem /* 16px */;
}

.mx-l {
  margin-left: 1.25rem /* 20px */;
  margin-right: 1.25rem /* 20px */;
}

.mx-xl {
  margin-left: 1.5rem /* 24px */;
  margin-right: 1.5rem /* 24px */;
}

.mx-xxl {
  margin-left: 1.75rem /* 28px */;
  margin-right: 1.75rem /* 28px */;
}

.mx-3xl {
  margin-left: 2rem /* 32px */;
  margin-right: 2rem /* 32px */;
}

.mx-4xl {
  margin-left: 2.5rem /* 40px */;
  margin-right: 2.5rem /* 40px */;
}

.mx-5xl {
  margin-left: 3.75rem /* 60px */;
  margin-right: 3.75rem /* 60px */;
}

/* Margin Auto Classes */

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
