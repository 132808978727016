.menuGroupItems {
  display: flex;
  flex-direction: column;
}

.menuGroupHeader {
  height: 32px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: var(--spacing-none) var(--spacing-xl);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  color: var(--color-text-secondary);
  opacity: 0.4;
}
