.uploadWrapper {
  background-color: var(--color-blue-surface);
  border-radius: var(--border-radius-s);
  margin-top: var(--spacing-xs);
}

.uploadWrapper :global(.ant-upload) {
  display: flex;
  flex: 1;
}

.upload {
  background-color: var(--color-surface2);
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
  border-radius: var(--border-radius-s);
  width: auto;
  height: auto;
  border: 1.5px dashed #E9ECEF;
  flex: 1;
}

.upload:global(.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover) {
  background-color: var(--color-surface2);
  border-color: #E9ECEF;
}

.circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
}

.uploadList {
  padding: var(--spacing-s);
}

.uploadList :global(.ant-list-item) {
  padding: var(--spacing-s) 20px;
  border-radius: var(--border-radius-m);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: var(--spacing-xs);
}

.fileName {
  flex: 1; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}
  