.playIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: var(--color-primary);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  cursor: pointer;
}

.playIcon.md {
  width: 44px;
  height: 44px;
}

.playIcon.sm {
  width: 20px;
  height: 20px;
}
