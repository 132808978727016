.filler {
  height: 32px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #E9ECEF;
  margin-bottom: 4px;
}

.card {
  width: 100%;
  text-align: center;
  overflow-y: hidden;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.09);
}

.card img {
  height: 30px;
}

.card:global(.ant-card-bordered .ant-card-body) {
  padding-bottom: 0;
}

.card:global(.ant-card-bordered) .branding {
  width: 100%;
  border-radius: var(--border-radius-xl);
  height: 44px;
  transform: translateY(30%);
}

.sportsman .card:global(.ant-card-bordered) .branding {
  background-color: var(--color-sportsman-brand);
}

.sportswoman .card:global(.ant-card-bordered) .branding {
  background-color: var(--color-sportswoman-brand);
}

.sportsman .card.selected:global(.ant-card-bordered){
  border-color: var(--color-sportsman-brand);
}

.sportswoman .card.selected:global(.ant-card-bordered) {
  border-color: var(--color-sportswoman-brand);
}

.wrapper :global(.ant-checkbox-checked .ant-checkbox-inner),
.wrapper :global(.ant-checkbox-checked .ant-checkbox-inner),  
.wrapper :global(.ant-checkbox-inner) {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.wrapper {
  cursor: pointer;
}

.wrapper :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
  height: 12px;
  width: 8px;
}

.sportsman :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color:  var(--color-sportsman-brand);
  border-color:  var(--color-sportsman-brand);
}

.sportswoman :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-sportswoman-brand);
  border-color: var(--color-sportswoman-brand);
}

@media (max-width: 768px) {
  .card {
    background-image: url(../../../assets/imagesV2/auth/smartphone_frame.svg);
    background-size: contain;
    height: 270px;
    width: 135px;
    box-shadow: none;
  }

  .card .filler:nth-child(3) {
    display: none;
  }

  .card img {
    height: 16px;
  }

  .card:global(.ant-card-bordered) .branding {
    height: 20px;
  }

  .card:global(.ant-card .ant-card-body) {
    padding-left:12px;
    padding-right: 12px;
    border: none;
  }

  .card:global(.ant-card-bordered),
  .sportsman .card.selected:global(.ant-card-bordered),
  .sportswoman .card.selected:global(.ant-card-bordered) {
    border-color: transparent;
    border: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}