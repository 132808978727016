.searchInput {
  width: 100%;
  position: relative;
}

.searchInput :global(.ant-select) {
  width: 100%;
}

.searchInput :global(.ant-select .ant-select-selection-search) {
  padding-left: 24px;
}

.searchInput :global(.ant-select .ant-select-selection-placeholder){
  padding-left: 24px;
  color: var(--color-text-primary);
}

.searchInput :global(.ant-select-clear) {
  right: 12px;
  width: 18px;
  height: 18px;
  top: 18px;
}

.pinIcon {
  position: absolute;
  left: 12px;
  top: 13px;
  z-index: 999988988;
}