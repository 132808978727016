.text-center {
  align-items: center;
}

.fw-normal {
  font-weight: normal;
}

.fw-500 {
  font-weight: 500;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-pre-wrap {
  white-space: no-wrap;
}

.text-color-primary {
  color: var(--color-primary);
}
