.thumbnails {
  height: 20%;
  width: 80%;
  margin: 10px auto;
  box-sizing: border-box;
  overflow-x: auto !important;
}

.thumbnails::-webkit-scrollbar {
  display: none;
}

.thumbnails {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.thumbnails :global(.swiper-slide) {
  height: 100%;
  opacity: 0.4;
}

.thumbnails :global(.swiper-slide-thumb-active) {
  opacity: 1;
}

.arrow {
  width: 40px; 
  height: 40px;
}

.arrow.left {
  margin-left: 10px;
}

.arrow.right {
  margin-right: 10px;
}
