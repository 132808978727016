.custom-select {
    max-width: 120px;
}

.custom-select.ant-select-disabled .ant-select-selector {
    border: none !important;
}

.custom-borderless-select .ant-select-selector {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    box-shadow: none !important;
    border-radius: 0;
}