.history {
  display: flex; 
  flex-direction: column; 
  flex: 1;
  max-height: calc(100vh - 80px)
}

.history.smallScreen {
  max-height: calc(100vh - 64px)
}

.header {
  background: #ffffff;
  padding: 10px 16px;
  border: 1.5px solid#E9ECEF;
  border-left: none;
  height: 85px;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
}

.footer {
  background-color: #fff;
  padding: 10px 20px;
  
  border-top: 1px solid #ddd
}

.footer :global(.ant-form) {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer :global(.ant-form .ant-btn) {
  border-radius: 10px;
}

.footer .input {
  background-color: var(--color-surface1);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  flex: 1;
  margin-left: var(--spacing-xs);
}

.footer :global(.ant-form .ant-form-item) {
  margin-inline-end: 0;
}
