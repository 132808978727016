$td-timepicker-font-size: $font-size-base;
$td-icon-size: $h4-font-size;
$td-widget-z-index: 9999;
$td-border-radius: $border-radius-sm;

$td-light: $white;

$td-widget-background: $td-light;
$td-font-color: $dark;
$td-dow-color: $td-font-color;
$td-disabled-color: rgba(0, 0, 0, 0.2);
$td-alternate-color: $gray-600;
$td-text-shadow: none;

$td-active-bg: $primary-second;
$td-active-color: $td-light;
$td-active-border-color: $td-light;

$td-range-bg: rgba($td-active-bg, 20%);
$td-range-color: $td-font-color;
$td-btn-hover-bg: $gray-200;
$td-primary-border-color: $td-light;
$td-secondary-border-color: transparentize($gray-400, 0.8);
$td-secondary-border-color-rgba: $td-secondary-border-color;

$td-dark: $gray-900;

$td-dark-widget-background: $td-dark;
$td-dark-font-color: $white;
$td-dark-dow-color: $td-dark-font-color;
$td-dark-disabled-color: rgba(255, 255, 255, 0.2);
$td-dark-alternate-color: $gray-400;
$td-dark-text-shadow: none;

$td-dark-active-bg: lighten($primary-second, 15%);
$td-dark-active-color: $white;
$td-dark-active-border-color: $td-dark;

$td-dark-range-bg: rgba($td-dark-active-bg, 40%);
$td-dark-range-color: $td-dark-font-color;
$td-dark-btn-hover-bg: $gray-800;
$td-dark-primary-border-color: $td-dark;
$td-dark-secondary-border-color: transparentize($gray-600, 0.8);
$td-dark-secondary-border-color-rgba: $td-dark-secondary-border-color;

@import "~@eonasdan/tempus-dominus/src/scss/tempus-dominus";

.tempus-dominus-widget {
  --day-size: 40px;
  --today-mark-size: 7px;
  --time-size: 34px;
  --space: 2px;

  &.light {
    --td-theme-bg-color: #{$td-light};
    --td-theme-active-bg: #{$td-active-bg};
    --td-theme-renge-bg: #{$td-range-bg};
    --td-theme-renge-color: #{$td-range-color};
    --td-theme-disabled-color: #{$td-disabled-color};
    --td-theme-btn-hover-bg: #{$td-btn-hover-bg};
    --td-theme-secondary-border-color: #{$td-secondary-border-color};
  }

  &.dark {
    --td-theme-bg-color: #{$td-dark};
    --td-theme-active-bg: #{$td-dark-active-bg};
    --td-theme-renge-bg: #{$td-dark-range-bg};
    --td-theme-renge-color: #{$td-dark-range-color};
    --td-theme-disabled-color: #{$td-dark-disabled-color};
    --td-theme-btn-hover-bg: #{$td-dark-btn-hover-bg};
    --td-theme-secondary-border-color: #{$td-dark-secondary-border-color};
  }

  box-shadow: $dropdown-box-shadow;
  background-color: var(--td-theme-bg-color);
  border-radius: var(--td-border-radius);
  padding: 10px;
  width: auto;
  min-width: 280px;

  &.inline {
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 1;
  }

  * {
    font-size: $h6-font-size;
  }

  i[class^="fa"] {
    font-size: $td-icon-size;
  }

  [data-action]:hover [class^="fa"] {
    color: var(--td-theme-active-bg);
  }

  .td-row {
    gap: var(--space);
  }

  .calendar-header {
    margin-bottom: var(--space);
    div:hover {
      background: var(--td-theme-btn-hover-bg);
      border-radius: var(--td-border-radius);
    }

    .previous,
    .next {
      line-height: 27px;
    }
  }

  .calendar-header,
  .date-container-decades,
  .date-container-years,
  .date-container-months,
  .time-container-clock,
  .time-container-hour,
  .time-container-minute,
  .time-container-second,
  .toolbar {
    gap: var(--space);
  }

  .date-container-decades,
  .date-container-years,
  .date-container-months,
  .time-container-hour,
  .time-container-minute,
  .time-container-second {
    grid-template-areas: "a a a a";
    grid-auto-rows: var(--day-size);
    grid-auto-columns: minmax(var(--day-size), 1fr);
    font-weight: 500;
    div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .date-container-days {
    grid-auto-rows: var(--day-size);
    grid-auto-columns: minmax(var(--day-size), 1fr);
    div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .time-container {
    margin: 0;
  }

  .time-container-clock {
    grid-auto-rows: minmax(var(--time-size), 1fr);
    grid-auto-columns: minmax(var(--time-size), 1fr) auto;
    height: 100%;
    div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .picker-switch {
    font-size: $h4-font-size;
    text-align: center;
  }

  .dow {
    @extend .border-bottom;
    @extend .py-2;
    border-bottom-color: var(--td-theme-secondary-border-color) !important;
    font-weight: 600;
  }

  .day {
    position: relative;
    background: none !important;
    font-weight: 500;

    &::after {
      content: "";
      margin: 0 !important;
      width: 26px !important;
      height: 26px !important;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      translate: -50% -50%;
      clip: auto !important;
      color: transparent !important;
    }
    &:hover:after {
      background-color: var(--td-theme-btn-hover-bg) !important;
    }
    &.active:not(.range-in):after {
      background-color: var(--td-theme-active-bg) !important;
    }

    &.disabled {
      color: var(--td-theme-disabled-color);
      &:after {
        background-color: var(--td-theme-disabled-color) !important;
        height: 1px !important;
        width: 20px !important;
        border-radius: 0;
        rotate: -45deg;
      }
    }

    &.today {
      &:before {
        content: "";
        display: inline-block;
        width: var(--today-mark-size) !important;
        height: var(--today-mark-size) !important;
        border: solid transparent !important;
        border-width: 0 0 var(--today-mark-size) var(--today-mark-size) !important;
        border-bottom-color: var(--td-theme-active-bg) !important;
        border-top-color: var(--td-theme-secondary-border-color) !important;
        position: absolute;
        top: auto;
        left: auto;
        bottom: 6px !important;
        right: 6px !important;
        transition: 0.3s ease-in-out;
      }
      &.active {
        background-color: var(--td-theme-active-bg);
        &:before {
          border-bottom-color: $white;
          z-index: 1;
          bottom: 2px !important;
          right: 2px !important;
        }
      }
    }
  }

  .day.selected-week {
    background-color: var(--td-theme-renge-bg) !important;
    border-radius: 0 !important;
    &-start {
      border-radius: 100px 0 0 100px !important;
    }
    &-end {
      border-radius: 0 100px 100px 0 !important;
    }
  }

  .range-start,
  .range-in,
  .range-end {
    background-color: var(--td-theme-renge-bg) !important;
    box-shadow: none !important;
  }
  .range-in {
    color: var(--td-theme-renge-color) !important;
  }

  .time-container-clock :not(.ho-highlight),
  .hour,
  .minute,
  .second {
    font-weight: bold;
  }

  .toggleMeridiem {
    border: none;
    height: 100%;
    line-height: 1;
  }

  .toolbar {
    margin-top: var(--space);
    color: var(--td-theme-active-bg);
  }
}
