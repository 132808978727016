:root
  --sportswoman-primary: #D92C9E
  --sportswoman-hover: #F24ACB
  --sportswoman-focus-shadow-rgb: 179, 36, 130
  --sportswoman-primary-rgb: 179, 36, 130

.form-switch
  .theme-toggle:not(:checked)
    border-color: var(--sportswoman-primary)
    background-color: var(--sportswoman-primary)
    --bs-form-switch-bg: url('../../src/assets/images/sportswoman_logo_circle.png') !important

  .theme-toggle:not(:checked):not(:focus)
    background-color: var(--sportswoman-primary)
    border-color: var(--sportswoman-primary)

  .theme-toggle:focus
    --bs-focus-ring-color: white

.theme-sportswoman
  a.link-underline.link-underline-opacity-0
    color: var(--sportswoman-primary)

  .btn-primary
    --bs-btn-color: white
    --bs-btn-bg: var(--sportswoman-primary)
    --bs-btn-border-color: none

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportswoman-hover)
    --bs-btn-hover-border-color: none

    --bs-btn-focus-shadow-rgb: var(--sportswoman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportswoman-hover)
    --bs-btn-active-border-color: none
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: white
    --bs-btn-disabled-bg: var(--sportswoman-primary)
    --bs-btn-disabled-border-color: none
    --td-theme-active-bg: var(--sportswoman-primary)

  .btn-primary-second
    --bs-btn-color: white
    --bs-btn-bg: var(--sportswoman-primary)
    --bs-btn-border-color: var(--sportswoman-primary)
    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportswoman-hover)
    --bs-btn-hover-border-color: var(--sportswoman-hover)
    --bs-btn-focus-shadow-rgb: var(--sportswoman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportswoman-hover)
    --bs-btn-active-border-color: var(--sportswoman-hover)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
    --bs-btn-disabled-color: white
    --bs-btn-disabled-bg: var(--sportswoman-primary)
    --bs-btn-disabled-border-color: var(--sportswoman-primary)
    --td-theme-active-bg: var(--sportswoman-primary)

  .bg-primary-second-hover:hover
    --bs-bg-opacity: .9
    background-color: rgba(var(--sportswoman-primary-rgb), var(--bs-bg-opacity)) !important

  .bg-primary-second
    --bs-primary-second-rgb: var(--sportswoman-primary-rgb)
    &.rounded-circle
      background-color: var(--sportswoman-primary) !important
    // background-color: rgba(var(--bs-primary-second-rgb), 1) !important

  .text-primary-second
    color: var(--sportswoman-primary) !important

  .btn-outline-primary
    --bs-btn-color: var(--sportswoman-primary)
    --bs-btn-border-color: var(--sportswoman-primary)

    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportswoman-primary)
    --bs-btn-hover-border-color: var(--sportswoman-primary)

    --bs-btn-focus-shadow-rgb: var(--sportswoman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportswoman-primary)
    --bs-btn-active-border-color: var(--sportswoman-primary)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)

    --bs-btn-disabled-color: var(--sportswoman-primary)
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: var(--sportswoman-primary)
    --bs-gradient: none

  .btn-outline-primary-second
    --bs-btn-color: var(--sportswoman-primary)
    --bs-btn-border-color: var(--sportswoman-primary)
    --bs-btn-hover-color: white
    --bs-btn-hover-bg: var(--sportswoman-primary)
    --bs-btn-hover-border-color: var(--sportswoman-primary)
    --bs-btn-focus-shadow-rgb: var(--sportswoman-focus-shadow-rgb)
    --bs-btn-active-color: white
    --bs-btn-active-bg: var(--sportswoman-primary)
    --bs-btn-active-border-color: var(--sportswoman-primary)
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
    --bs-btn-disabled-color: var(--sportswoman-primary)
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: var(--sportswoman-primary)
    --bs-gradient: none

  .form-control:focus, .react-tel-input .form-control:focus
    border-color: var(--sportswoman-primary)
    & ~ .input-group-text
      border: var(--bs-border-width) solid var(--sportswoman-primary)

  .form-select:focus
    border-color: var(--sportswoman-primary)
    box-shadow: none !important

  .tempus-dominus-widget.light
    --td-theme-active-bg: var(--sportswoman-primary)
    --td-theme-renge-bg: rgba(217, 44, 158, 0.2)

  .dropdown-item.active, .dropdown-item:active
    --bs-dropdown-link-active-bg: var(--sportswoman-primary)

  .theme-toggle:checked
    border-color: #0052b4

  .theme-toggle

  .form-check-input:checked
    background-color: white
    border-color: var(--sportswoman-primary)

  .form-check-input:checked[type=checkbox]
    --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>")
    background-color: var(--sportswoman-primary)

  .form-check-input:checked[type=radio]
    --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23D92C9E'/></svg>")

  .form-check-input:focus
    border-color: var(--sportswoman-primary)
    box-shadow: 0 0 0 0.2rem rgba(217, 44, 158, 0.2)

  .bg-primary-checked:checked
    --bs-bg-opacity: 1
    background-color: rgba(var(--sportswoman-primary-rgb), var(--bs-bg-opacity)) !important

  .border-primary-checked:checked
    --bs-border-opacity: 1
    --bs-border-color: rgba(var(--sportswoman-primary-rgb), var(--bs-border-opacity)) !important
    --bs-border-opacity: 1
    border-color: rgba(var(--sportswoman-primary-rgb), var(--bs-border-opacity)) !important

  .focus-ring-primary
    --bs-focus-ring-color: rgba(var(--sportswoman-primary-rgb), var(--bs-focus-ring-opacity))

  .border-primary-focus:focus
    --bs-border-color: rgba(var(--sportswoman-primary-rgb), var(--bs-border-opacity)) !important
    border-color: rgba(var(--sportswoman-primary-rgb), var(--bs-border-opacity)) !important

  .input-group-text
    border: var(--bs-border-width) solid #e9ecef

  .input-group > .reversed-group-text
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important
    border-top-left-radius: 0.5rem !important
    border-bottom-left-radius: 0.5rem !important

  .reversed-input-group.input-group .form-control
    border-top-right-radius: 0.5rem !important
    border-bottom-right-radius: 0.5rem !important
    border-top-left-radius: 0rem !important
    border-bottom-left-radius: 0rem !important

  .dropdown-menu
    --bs-dropdown-bg: white
    --bs-dropdown-border-color: var(--sportswoman-primary)
    --bs-dropdown-box-shadow: 0 0 1rem rgba(29, 114, 244, 0.15)

  .dropdown-item.active, .dropdown-item:active
    --bs-dropdown-link-active-bg: var(--sportswoman-primary)

  .wizard-step .border-primary-second
    border-color: rgba(var(--sportswoman-primary-rgb), var(--bs-border-opacity)) !important
