@import "https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600&family=Roboto+Flex:wght@400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap";

@import "functions";
@import "vars";

@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";

@import "~swiper/scss";
@import "~swiper/scss/free-mode";
@import "~swiper/scss/mousewheel";
@import "~swiper/scss/navigation";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/pagination";

// Global Styles
@import "global";

@import "components/menu_btn";
