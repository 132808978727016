.layout {
  height: 100vh;
  overflow: auto;
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
  padding: var(--spacing-l) var(--spacing-4xl);
  z-index: 10000;
  box-shadow: 0px 0px 11px 0px #00000017;
}

.logo {
  margin-right: auto;
  cursor: pointer;
}

.logoImage {
  height: var(--spacing-4xl);
}

.menuIcon {
  cursor: pointer;
  color: var(--color-text-secondary);
  display: none;
}

.menuIcon:hover {
  color: var(--color-text-primary);
}

.avatar {
  width: 44px;
  height: 44px;
}

.avatar:hover {
  opacity: 0.9;
}

.logoutBtn {
  padding: 0px var(--spacing-xl);
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 76px;
  }

  .logo {
    margin-left: var(--spacing-3xl);
  }

  .logoImage {
    height: var(--spacing-3xl);
  }

  .menuIcon {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 64px;
    padding: var(--spacing-l) var(--spacing-m);
  }

  .logo {
    margin-left: auto;
  }

  .logoImage {
    height: var(--spacing-xxl);
  }

  .logoutBtn {
    display: none;
  }
}
