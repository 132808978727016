.wrapper {
  display: flex;
  height: 72px;
  align-items: center;
  gap: var(--spacing-s);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.categoryWrapper {
  display: flex;
  gap: var(--spacing-xs);
  color: var(--color-text-primary);

  .icon {
    width: 18.4px;
    height: 18.4px;
  }
}

.subPages {
  display: flex;
  gap: var(--spacing-s);
  color: var(--color-primary);
}
