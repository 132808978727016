@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $columns > 0 {
        @for $i from 1 through $columns {
          .g-col#{$infix}-#{$i} {
            grid-column: auto / span $i;
          }
        }

        // Start with `1` because `0` is and invalid value.
        // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
        @for $i from 1 through ($columns - 1) {
          .g-start#{$infix}-#{$i} {
            grid-column-start: $i;
          }
        }

        @for $i from 1 through $columns {
          .g-row#{$infix}-#{$i} {
            grid-row: auto / span $i;
          }
        }

        // Start with `1` because `0` is and invalid value.
        // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
        @for $i from 1 through ($columns - 1) {
          .g-row-start#{$infix}-#{$i} {
            grid-row-start: $i;
          }
        }
      }
    }
  }
}
