.menuGroupItem {
  height: 48px;
  display: flex;
  align-items: center;
  padding: var(--spacing-none) var(--spacing-xl);
  cursor: pointer;
  gap: var(--spacing-xs);
  color: var(--color-text-primary);
  font-weight: 400;
  border-radius: var(--border-radius-xs);
}

.itemName {
  font-size: 16px;
  line-height: 22.4px;
  padding-top: 2px;
}

.itemIcon {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.menuGroupItem:hover {
  background-color: var(--color-surface1);
}

.menuGroupItemActive {
  color: var(--color-primary);
  font-weight: 500;
}
